import React from "react"
import "../assets/scss/app.scss"
import "bootstrap/dist/css/bootstrap.min.css"
import HomeLayout from "../components/HomeLayout"

const Home = () => {
  return (
    <HomeLayout title={"Strona Główna"}>
      <h4 className="text-center pb-2">
        Witaj na stronie internetowej firmy{" "}
        <span className="fw-bold">INWESTPROJEKT</span>
      </h4>
      <h4 className={"text-center"}>Zaprojektujemy twój wymarzony dom</h4>
    </HomeLayout>
  )
}

export default Home
